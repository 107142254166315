.items {
    padding-bottom: 22vw;
    margin-top: 1vw;
}

.item {
    margin-bottom: 4vw;
}

.label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: var(--colorLightGray);
    border-radius: 2.666666vw;
    padding: 8vw 5vw;
}

.radioInput {
    position: absolute;
    left: -9999px;
    opacity: 0;
}

.radioIcon {
    position: relative;
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    border: 1px solid var(--colorGrayBorder2);
    background-color: white;
    margin-right: 2.666666vw;
    transition: background-color var(--trDur), border-color var(--trDur);
}

.radioIcon:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 46%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 2.5vw;
    height: 1.2vw;
    border-bottom: 1px solid var(--colorWhite);
    border-left: 1px solid var(--colorWhite);
}

.radioInput:checked ~ .radioIcon {
    background-color: var(--colorBlack);
    border: 1px solid var(--colorBlack);
}

.radioTitle {
    font-size: 3.4vw;
}

.paymentImages {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymentImages img {
    margin-left: 3vw;
    max-width: 16.4vw;
    height: 6.4vw;
}